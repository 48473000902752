import { useState } from 'react';
import useListFlows from '../hooks/useListFlows';

function useListFlowsInteraction () {

  const [_page, setPage] = useState<number>(1);

  const { data, status } = useListFlows(_page)

  const hasNextPage = data?.page < data?.totalPages;
  const hasPreviousPage = data?.page !== 1;

  const goToPage = (newPage) => {
    if (newPage > 0 && newPage <= data?.totalPages) {
      setPage(newPage);
    }
  };

  const getPage = (num = 1) => {
    if (num === 1 && !hasNextPage) {
      return;
    }
    if (num === -1 && !hasPreviousPage) {
      return;
    }
    setPage(_page + num);
  };

  return { data, status, hasNextPage, hasPreviousPage, goToPage, setPage, back: () => getPage(-1), next: () => getPage(1) };
};

export default useListFlowsInteraction;
