/* eslint-disable import/prefer-default-export */
const domains = {
  localhost   : 'https://development-api.buildable.io',
  development : 'https://development-api.buildable.io',
  beta        : 'https://beta-api.buildable.dev',
  prod        : 'https://api.buildable.dev',
  default     : 'https://development-api.buildable.dev',
};

function getUrl(environment) {
  const domain = domains[environment] ?? domains.development;
  return `${domain}/internal/v1`;
}

// const env = process.env.NEXT_PUBLIC_ENV || 'default';
export const path = getUrl(process.env.NEXT_PUBLIC_ENV);
// console.log('testing env vars', process.env.NEXT_PUBLIC_ENV, env, path)

export const apiKeys = {
  'get.logicFlow'       : `${path}/flows/get`,
  'get.logicFlows'      : `${path}/flows/list`,
  'get.autoComplete'    : `${path}/flows/get-types`,
  'get.emptyNode'       : `${path}/flows/get.empty.node`,

  'create.logicFlow'    : `${path}/flows/create.empty.flow`,
  'create.node'         : `${path}/flows/add.node`,

  'deploy.logicFlow'    : `${path}/flows/deploy`,

  'delete.node'         : `${path}/flows/delete.node`,
  'delete.flow'         : `${path}/flows/delete`,

  'fork.logicFlow'      : `${path}/flows/fork`,

  'update.node'         : `${path}/flows/update.single.node`,
  'update.exec'         : `${path}/flows/update.single.node`,
  'update.logicFlow'    : `${path}/flows/update`,
  'update.response'     : `${path}/flows/update.response`,

  'search.flows'        : `${path}/flows/search`,

  // µservices
  'create.microservice' : `${path}/microservices/create`,
  'get.microservices'   : `${path}/microservices/list-frontend`,
  'sync.microservice'   : `${path}/microservices/sync-and-generate-types`,
  'delete.microservice' : `${path}/microservices/delete`,
  'list.frontend.microservice': `${path}/microservice/list-frontend`,
  'upload.media': "https://upload.buildable.dev/upload/live-2a80d157-ecb0-4279-ac1d-901b93513b9b"
};
