/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getCookie } from '../../helpers/utility';
import config from '../../config';

const activeEnv = process.env.NEXT_PUBLIC_ENV || 'development';

const apiEndpoints = {
  development: 'https://development-api.buildable.io/trigger',
  staging: 'https://staging-api.buildable.io/trigger',
  beta: 'https://beta-api.buildable.dev/trigger',
  production: 'https://api.buildable.dev/trigger',
  localhost: 'https://development-api.buildable.io/trigger',
};

const recipeTriggerEnvs = {
  development: 'test',
  staging: 'test',
  beta: 'live',
  production: 'live',
  localhost: 'test',
};

const recipeUrl = apiEndpoints[activeEnv];
const version2 = 'v2';
const recipeTrigger = recipeTriggerEnvs[activeEnv];
const POST = 'POST';

interface IProps {
  triggerId: string;
  payload?: any;
  env?: string;
}

interface ApiIProps {
  url: string;
  payload?: any;
  userToken?: string;
  injectToken?: boolean;
  headers?: { [x: string]: string };
  method?: 'POST' | 'GET';
}

export const api = async ({
  url,
  payload,
  userToken,
  injectToken = true,
  headers = {},
  method = 'POST',
}: ApiIProps) => {
  const customHeader = injectToken
    ? {
        ...headers,
        Authorization: userToken ? `Bearer ${userToken}` : null,
      }
    : headers;

  const { data } = await axios({
    method,
    url,
    headers: customHeader,
    data: payload,
  });

  return { ...data };
};

const redirectToWelcome = () => window.location.assign(config.welcomeUrl);

export const ensureAuth = (fallback = redirectToWelcome) => {
  const cookieWithUser = getCookie();
  if (!cookieWithUser?.authKey) {
    fallback();
    return null;
  }
  return cookieWithUser;
};

export const dispatchRecipe = ({
  triggerId,
  payload,
  env = recipeTrigger,
}: IProps): Promise<any> => {
  const cookieWithUser = ensureAuth();
  const userTokenForApi = cookieWithUser?.authKey || null;

  if (!recipeUrl) {
    console.warn('No url for recipe');
    return null;
  }

  return api({
    url: `${recipeUrl}/${version2}/${env}-${triggerId}`,
    userToken: userTokenForApi,
    payload,
  });
};

interface ApiWithToken {
  url: string;
  method: 'GET' | 'POST';
  payload: any;
  headers?: { [x: string]: string };
}

export const apiWithToken = ({
  url,
  headers,
  method,
  payload,
}: ApiWithToken) => {
  const cookieWithUser = ensureAuth();
  const userToken = cookieWithUser?.authKey || null;

  return api({
    url,
    headers,
    injectToken: true,
    method,
    userToken,
    payload,
  });
};

export default dispatchRecipe;
