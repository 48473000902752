const envFlags = {
  staging: 'staging-',
  beta: 'beta-',
  production: '',
};

const hostList = {
  development: 'localhost',
  staging: 'buildable.io',
  beta: 'buildable.dev',
  production: 'buildable.dev',
};

const welcomeDomain = {
  development: 'http://localhost:4501',
  staging: 'https://staging-welcome.buildable.io/',
  beta: 'https://welcome.buildable.dev',
  production: 'https://welcome.buildable.dev',
};

const flowSecureCallerEndpoints = {
  development: 'https://development-api.buildable.io/secure-flow/v1/call/',
  staging: `https://staging-api.buildable.io/secure-flow/v1/call/`,
  beta: `https://beta-api.buildable.dev/secure-flow/v1/call/`,
  production: `https://api.buildable.dev/secure-flow/v1/call/`,
  localhost: 'https://development-api.buildable.io/secure-flow/v1/call/',
};

export const flowSecureCallerEndpoint = flowSecureCallerEndpoints[process.env.NEXT_PUBLIC_ENV];

const flowCallerEndpoints = {
  development: 'https://development-api.buildable.io/flow/v1/call/',
  staging: `https://staging-api.buildable.io/flow/v1/call/`,
  beta: `https://beta-api.buildable.dev/flow/v1/call/`,
  production: `https://api.buildable.dev/flow/v1/call/`,
  localhost: 'https://development-api.buildable.io/flow/v1/call/',
};

export const flowCallerEndpoint = flowCallerEndpoints[process.env.NEXT_PUBLIC_ENV];

const domains = {
  onboarding: {
    development: 'http://localhost:4201',
    staging: 'https://staging-welcome.buildable.io',
    beta: 'https://beta-welcome.buildable.dev',
    production: 'https://welcome.buildable.dev',
  },
  microservices: {
    development: 'http://localhost:4502',
    staging: 'https://staging-app.buildable.io',
    beta: 'https://beta-app.buildable.dev',
    production: 'https://app.buildable.dev',
  },
  canvas: {
    development: 'http://localhost:4501',
    staging: 'https://staging-canvas.buildable.io',
    beta: 'https://beta-canvas.buildable.dev',
    production: 'https://canvas.buildable.dev',
  },
};

const welcomeUrl = welcomeDomain[process.env.NEXT_PUBLIC_ENV];
const builderUrl = domains.canvas[process.env.NEXT_PUBLIC_ENV];
export const microservicesUrl = domains.microservices[process.env.NEXT_PUBLIC_ENV];

const host = hostList[process.env.NEXT_PUBLIC_ENV];

export default {
  cookies: {
    name: 'buildable',
    visitor: 'buildable',
    expiry: 200,
    domain: host,
  },
  microservicesUrl,
  welcomeUrl,
  builderUrl,
  activeEnv: process.env.NEXT_PUBLIC_ENV,
  host,
};
